<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Certificates" backTo="service">
      <router-link
          :to="{ name: 'certificate-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14"/>
        </svg>
        <span>Create Certificate</span>
      </router-link>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-0 mr-2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected"/>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <div class="flex items-center mr-4">
                <label class="mr-2">From Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="fromDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <div class="flex items-center ">
                <label class="mr-2">To Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="toDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>

              <StaffAutocomplete class="ml-6 w-full lg:w-1/5 xl:w-1/5 2xl:w-1/5" v-model="selectedEngineerInput"
                                 :forceSelection="true"/>

              <input
                  type="text"
                  class="ml-6 w-full lg:w-auto rounded-l bge-input bge-input-spacing"
                  placeholder="Search"
                  v-model="searchTextInput"
                  @keydown.enter="triggerSearch"
              />
              <button
                  @click="triggerSearch"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <Icon iconType="search"/>
              </button>
            </div>
          </div>
        </div>

        <!-- Tab Content -->
        <TabContent :currentTab="currentTab">
          <template v-if="currentTab === 'all_certificates'" v-slot:all_certificates>
            <DataTableComponent :columns="certificate_columns" @row-click="onRowClick" rowGroupMode="rowspan"
                                groupRowsBy="certificate_date" key="all_certificates"
            >

              <template v-slot:issue-date-slot="{ rowData }">
                {{ formatTimestamp(rowData.issue_date) }}
              </template>

              <template v-slot:expiry-date-slot="{ rowData }">
                {{ formatTimestamp(rowData.expiry_date) }}
              </template>

              <template v-slot:customer-name-slot="{ rowData }">
                <div v-if="rowData.customer">
                  {{ rowData.customer.name }}
                </div>
              </template>

              <template v-slot:engineer-name-slot="{ rowData }">
                <span v-if="rowData.engineer">
                  {{ rowData.engineer.name }}
                </span>
              </template>

              <template v-slot:certificate-id-slot="{ rowData }">
                <router-link
                    class="text-blue-600 hover:text-blue-800 underline cursor-pointer"
                    :to="{ name: 'certificate-detail', params: { certificate_id: rowData.certificate_id }}"
                >
                  {{ rowData.certificate_number }}
                </router-link>
              </template>

            </DataTableComponent>
          </template>
        </TabContent>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/HR/Engineer/Certificate/Components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";

export default {
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Spinner,
    Panel,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
    StaffAutocomplete
  },
  data() {
    return {
      tabs: [
        {id: "all_certificates", label: "Certificates",},
      ],
      certificate_columns: [
        {
          field: "certificate_number",
          header: "Certificate No.",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "equipment_model",
          header: "Code",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "equipment_description",
          header: "Equipment Description",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "equipment.serial_number",
          header: "Serial Number",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "customer.name",
          header: "Customer",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "customer_details.default_address.postcode",
          header: "Postcode",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "issue_date",
          header: "Issue Date",
          sortable: true,
          custom: true,
          slotName: "issue-date-slot",
          width: '10%',
        },
        {
          field: "expiry_date",
          header: "Expiry Date",
          sortable: true,
          custom: true,
          slotName: "expiry-date-slot",
          width: '10%',
        },
        {
          field: "engineer.name",
          header: "Engineer",
          sortable: true,
          custom: false,
          width: '10%',
        },
      ],
    };
  },
  computed: {
    ...mapState("certificate", ["currentTab", "searchText", "selectedEngineer", "fromDate", "toDate"]),

    searchTextInput: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.$store.commit("certificate/UPDATE_SEARCH_TEXT", value);
      },
    },

    selectedEngineerInput: {
      get() {
        return this.selectedEngineer;
      },
      set(value) {
        this.$store.commit("certificate/UPDATE_SELECTED_ENGINEER", value);
      },
    },

    fromDateInput: {
      get() {
        const fromDate = this.fromDate;
        return fromDate ? new Date(fromDate) : null;
      },
      set(value) {
        this.$store.commit("certificate/UPDATE_FROM_DATE", value);
      },
    },

    toDateInput: {
      get() {
        const toDate = this.toDate;
        return toDate ? new Date(toDate) : null;
      },
      set(value) {
        this.$store.commit("certificate/UPDATE_TO_DATE", value);
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("certificate", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
    ]),

    validateDates() {
      const hasFromDate = !!this.fromDateInput;
      const hasToDate = !!this.toDateInput;

      if (hasFromDate && hasToDate) {
        if (this.toDateInput < this.fromDateInput) {
          this.notifyWarning("To Date should not be earlier than From Date.");
          return false;
        }
        return true;
      }

      if (hasFromDate !== hasToDate) {
        this.notifyWarning("Please select both the From Date and To Date.");
        return false;
      }

      return true;
    },

    onTabSelected(newTab) {
      this.updateTab(newTab);
    },

    triggerSearch() {
      if (this.validateDates()) {
        this.updateTab("all_certificates");
      }
    },

    onRowClick(event) {

      this.$router.push({
        name: 'certificate-edit',
        params: {certificate_id: event.data.certificate_id}
      });
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
  },
};
</script>
